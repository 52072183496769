import React from 'react'
import { Typography, Link } from '@mui/material';

const Footer = (props) => {
    return (
        <div>
            <Typography align="center">
                Copyright 2024 by Aurascape.ai
                {'  '}|{'  '}
                <Link href="/terms.pdf" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </Link>
                {'  '}|{'  '}
                <Link href="/privacy.pdf" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </Link>
            </Typography>
        </div>
    )
}

export default Footer