import { ThemeProvider } from '@mui/material/styles';

import { Box } from '@mui/material';

import theme from './theme';

import Header from './components/Header'
import Footer from './components/Footer'
import WindowsClient from './components/WindowsClient';
import MacOSClient from './components/MacOSClient';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ m: 10 }}>
        <Header />
        <WindowsClient />
        <MacOSClient />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
